import React from 'react';
import {
    Paper,
    Typography,
    Box,
    ToggleButton,
    ToggleButtonGroup,
    IconButton,
    Collapse,
    Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type FilterLayoutProps = {
    selectedGen: number;
    handleGenChange: (event: React.MouseEvent<HTMLElement>, newGen: number) => void;
    totalFilteredPokemon: number;
    expanded: boolean;
    toggleExpanded: () => void;
};

const generations = [1, 2, 3, 4, 5, 6, 7, 8, 9];

const DesktopPokedexFilter: React.FC<FilterLayoutProps> = ({
    selectedGen,
    handleGenChange,
    totalFilteredPokemon,
    expanded,
    toggleExpanded,
}) => {
    return (
        <Paper elevation={4} style={{ width: '100%', padding: '16px 16px 0px 16px', marginBottom: '16px' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Box display="flex" alignItems="center">
                    <Typography variant="h6" mr={2}>
                        Filters
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary">
                        Total Pokémon: {totalFilteredPokemon}
                    </Typography>
                </Box>
                <IconButton onClick={toggleExpanded} size="small">
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={expanded}>
                <Box mb={2}>
                    <ToggleButtonGroup
                        value={selectedGen}
                        exclusive
                        onChange={handleGenChange}
                        aria-label="generations"
                        fullWidth
                    >
                        <Grid container spacing={1}>
                            {generations.map((gen) => (
                                <Grid item xs={4} sm={3} md={2} lg={1} key={gen}>
                                    <ToggleButton value={gen} aria-label={`Generation ${gen}`} fullWidth>
                                        Gen {gen}
                                    </ToggleButton>
                                </Grid>
                            ))}
                        </Grid>
                    </ToggleButtonGroup>
                </Box>
            </Collapse>
        </Paper>
    );
};

export default DesktopPokedexFilter;