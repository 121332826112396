import { toast } from 'react-toastify';

interface CardApiResponse {
    success: boolean;
    message?: string;
    totalCards?: number;
    featuredPokemonCards?: number;
    cameoPokemonCards?: number;
    featuredCaughtPokemonCards?: number;
    cameoCaughtPokemonCards?: number;
    pokedexValue?: number;
}

interface UserCaughtPokemonResponse extends CardApiResponse {
    caughtPokemon?: number;
}

const handleApiError = (error: unknown, errorMessage: string) => {
    console.error(errorMessage, error);
    toast.error(errorMessage);
    return { success: false };
};

export const getFeaturedPokemonCardCount = async (): Promise<CardApiResponse> => {
    try {
        const response = await fetch('/api/getFeaturedPokemonCardCount', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const result: CardApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch featured Pokemon card count');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching featured Pokemon card count');
    }
};

export const getCameoPokemonCardCount = async (): Promise<CardApiResponse> => {
    try {
        const response = await fetch('/api/getCameoPokemonCardCount', {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const result: CardApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch cameo Pokemon card count');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching cameo Pokemon card count');
    }
};

export const getUserCaughtPokemon = async (username: string): Promise<UserCaughtPokemonResponse> => {
    try {
        const response = await fetch(`/api/getUserCaughtPokemon?username=${encodeURIComponent(username)}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const result: UserCaughtPokemonResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch user caught Pokemon count');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching user caught Pokemon count');
    }
};

export const getFeaturedCaughtPokemonCardCount = async (username: string): Promise<CardApiResponse> => {
    try {
        const response = await fetch(`/api/getFeaturedCaughtPokemonCardCount?username=${encodeURIComponent(username)}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const result: CardApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch featured caught Pokemon card count');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching featured caught Pokemon card count');
    }
};

export const getCameoCaughtPokemonCardCount = async (username: string): Promise<CardApiResponse> => {
    try {
        const response = await fetch(`/api/getCameoCaughtPokemonCardCount?username=${encodeURIComponent(username)}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        });

        const result: CardApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to fetch cameo caught Pokemon card count');
        }

        return result;
    } catch (error) {
        return handleApiError(error, 'An error occurred while fetching cameo caught Pokemon card count');
    }
};

export const getUserPokedexValue = async (authParams: any, username: string): Promise<CardApiResponse> => {
    console.log('getUserPokedexValue called with username:', username);
    console.log('authParams:', authParams);

    if (!authParams) {
        console.error('authParams is null or undefined');
        return { success: false, message: 'Authentication parameters are missing' };
    }

    if (!username) {
        console.error('Username is null or undefined');
        return { success: false, message: 'Username is missing' };
    }

    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const requestBody = {
            ...stringAuthParams,
            username: username, // Make sure username is included in the request
        };

        const queryString = new URLSearchParams(requestBody).toString();
        console.log('Fetching Pokedex value with URL:', `/api/getUserPokedexValue?${queryString}`);
        
        const response = await fetch(`/api/getUserPokedexValue?${queryString}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        const result: CardApiResponse = await response.json();
        console.log('getUserPokedexValue result:', result);

        if (!result.success) {
            toast.error('Failed to fetch user Pokedex value');
        }

        return result;
    } catch (error) {
        console.error('Error in getUserPokedexValue:', error);
        return handleApiError(error, 'An error occurred while fetching user Pokedex value');
    }
};
