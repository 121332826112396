import React from 'react';
import { Paper, Typography, Box, Avatar } from '@mui/material';
import CatchingPokemonIcon from '@mui/icons-material/CatchingPokemon';

interface PokemonCardProps {
  pokemon: {
    id: number;
    name: string;
    type: string;
    gen: number;
  };
  caughtFeatureCards: number;
  caughtCameoCards: number;
  hasCaught: boolean;
}

const MobilePokemonCard: React.FC<PokemonCardProps> = ({ pokemon, caughtFeatureCards, caughtCameoCards, hasCaught }) => {
  return (
    <Paper 
      elevation={3} 
      sx={{ 
        p: 1, 
        position: 'relative', 
        height: '100%', 
        display: 'flex', 
        flexDirection: 'column',
        transition: 'box-shadow 0.3s, transform 0.3s',
        '&:active': {
          boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
          transform: 'translateY(-2px)',
        }
      }}
    >
      <Typography variant="subtitle1" align="center" gutterBottom>
        {pokemon.name}
      </Typography>
      <Box display="flex" justifyContent="center" mb={1}>
        <Avatar
          src={`https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/${pokemon.id}.png`}
          alt={pokemon.name}
          sx={{ width: 64, height: 64 }}
        />
      </Box>
      <Typography variant="body2" align="center" gutterBottom>
        #{pokemon.id.toString().padStart(3, '0')}
      </Typography>
      <Box mt="auto" display="flex" justifyContent="space-between">
        <Typography variant="caption">
          F: {caughtFeatureCards}
        </Typography>
        <Typography variant="caption">
          C: {caughtCameoCards}
        </Typography>
      </Box>
      {hasCaught && (
        <CatchingPokemonIcon
          color="primary"
          sx={{ position: 'absolute', bottom: 4, right: 4, fontSize: '1rem' }}
        />
      )}
    </Paper>
  );
};

export default MobilePokemonCard;