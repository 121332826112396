import React, { useState, useRef, useEffect } from 'react';
import { Autocomplete, TextField, CircularProgress } from '@mui/material';
import { usePokeDex, Pokemon } from '../../hooks/usePokeDex';
import ClearIcon from '@mui/icons-material/Clear';
import '../../css/popup.css';
import { useDispatch } from 'react-redux';
import { setFavoritePokemonImage } from '../../redux/slices/authSlice';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import CountrySelector from '../selectors/CountrySelector';
import { useUserFunctions } from '../../hooks/useUserFunctions';

interface EditProfilePopupProps {
    open: boolean;
    onClose: () => void;
    onSave: (pokemonId: number, countryCode: string) => void;
    currentFavoritePokemon: number | null;
    currentFavoritePokemonImage: string | null;
    currentCountryCode: string;
}

const EditProfilePopup: React.FC<EditProfilePopupProps> = ({
    open,
    onClose,
    onSave,
    currentFavoritePokemon,
    currentFavoritePokemonImage,
    currentCountryCode,
}) => {
    const [selectedCountry, setSelectedCountry] = useState<string>(currentCountryCode);
    const [selectedPokemon, setSelectedPokemon] = useState<Pokemon | null>(null);
    const { pokedex, loading, hasMore, fetchPokedex } = usePokeDex();
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useDispatch();
    const { updateFavoritePokemon, updateUserCountry } = useUserFunctions();

    useEffect(() => {
        if (currentFavoritePokemon && pokedex.length > 0) {
            const currentPokemon = pokedex.find((p) => p.id === currentFavoritePokemon);
            setSelectedPokemon(currentPokemon || null);
        }
    }, [currentFavoritePokemon, pokedex]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        if (open) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [open, onClose]);

    const handleSave = async (event: React.MouseEvent) => {
        event.stopPropagation();
        let updatedSuccessfully = true;
        let countryUpdated = false;
        let pokemonUpdated = false;

        if (selectedCountry && selectedCountry !== currentCountryCode) {
            try {
                const success = await updateUserCountry(selectedCountry);
                if (success) {
                    countryUpdated = true;
                } else {
                    updatedSuccessfully = false;
                }
            } catch (error) {
                console.error('Error updating country:', error);
                updatedSuccessfully = false;
            }
        } else {
            console.log('No change in country, skipping update');
        }

        if (selectedPokemon && selectedPokemon.id !== currentFavoritePokemon) {
            try {
                const success = await updateFavoritePokemon(selectedPokemon.id);
                if (success) {
                    dispatch(setFavoritePokemonImage(selectedPokemon.image));
                    pokemonUpdated = true;
                } else {
                    updatedSuccessfully = false;
                }
            } catch (error) {
                console.error('Error updating favorite Pokemon:', error);
                updatedSuccessfully = false;
            }
        } else {
            console.log('No change in favorite Pokémon, skipping update');
        }

        if (updatedSuccessfully) {
            onSave(
                pokemonUpdated ? selectedPokemon!.id : currentFavoritePokemon || 0,
                countryUpdated ? selectedCountry : currentCountryCode
            );
            onClose();
        }
    };

    const handleContainerMouseDown = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    const handleScroll = (event: React.UIEvent<HTMLUListElement>) => {
        const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
        if (scrollHeight - scrollTop === clientHeight && hasMore && !loading) {
            fetchPokedex();
        }
    };

    if (!open) return null;

    return (
        <div className="popup-overlay" onMouseDown={onClose}>
            <div
                ref={containerRef}
                className="popup-container"
                onMouseDown={handleContainerMouseDown}
            >
                <div className="popup-header">
                    <h2 className="popup-title">Edit Profile</h2>
                    <button
                        className="popup-close-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                    >
                        <ClearIcon />
                    </button>
                </div>
                <div className="popup-content">
                    <div className="profile-pic-section">
                        <LazyLoadImage
                            src={currentFavoritePokemonImage || ''}
                            alt="Current Favorite Pokémon"
                            effect="blur"
                            width={100}
                            height={100}
                            style={{ borderRadius: '50%' }}
                        />
                    </div>
                    <div className="favorite-pokemon-section">
                        <h3 className="section-title">Change Favorite Pokémon</h3>
                        <p className="section-description">
                            Select a new Pokémon to set as your favorite. This will be displayed as
                            your profile picture.
                        </p>
                        <Autocomplete<Pokemon>
                            id="favorite-pokemon"
                            options={pokedex}
                            groupBy={(option: Pokemon) => `Generation ${option.generation}`}
                            getOptionLabel={(option: Pokemon) => option.name}
                            renderOption={(props, option: Pokemon) => (
                                <li {...props} style={{ display: 'flex', alignItems: 'center' }}>
                                    <LazyLoadImage
                                        src={option.image}
                                        alt={option.name}
                                        width={50}
                                        height={50}
                                        effect="blur"
                                        style={{ marginRight: 15 }}
                                    />
                                    <span style={{ fontSize: '16px' }}>
                                        {option.number} - {option.name}
                                    </span>
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Favorite Pokémon"
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <>
                                                {loading ? (
                                                    <CircularProgress color="inherit" size={20} />
                                                ) : null}
                                                {params.InputProps.endAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                            value={selectedPokemon}
                            onChange={(event, newValue) => {
                                setSelectedPokemon(newValue);
                            }}
                            loading={loading}
                            ListboxProps={{
                                style: { maxHeight: '300px', overflowY: 'auto' },
                                onScroll: handleScroll,
                            }}
                            onMouseDown={(event) => event.stopPropagation()}
                            onClick={(event) => event.stopPropagation()}
                        />
                    </div>
                    <div className="country-section">
                        <h3 className="section-title">Change Country</h3>
                        <p className="section-description">
                            Select your country. This will be displayed on your profile.
                        </p>
                        <CountrySelector
                            onChange={(code) => setSelectedCountry(code)}
                            value={selectedCountry}
                        />
                    </div>
                </div>
                <div className="popup-footer">
                    <button
                        className="popup-button popup-button-secondary"
                        onClick={(e) => {
                            e.stopPropagation();
                            onClose();
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="popup-button popup-button-primary"
                        onClick={handleSave}
                        disabled={!selectedPokemon && selectedCountry === currentCountryCode}
                    >
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EditProfilePopup;
