// React and related imports
import React, { useState, useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../redux/store';

// Material-UI
import { useMediaQuery, Fab, useScrollTrigger, Zoom } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// Third-party libraries
import ReactGA from 'react-ga4';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';

// Custom hooks and contexts
import { useAuth } from '../../../context/AuthContext';

// Utility functions and data
import {
    handleAPIResult,
    addCardToCollection,
    removeCardFromCollection,
} from '../../../components/common/reuseablethings';
import assets from '../../../assets';
import defaultCards from '../../../configs/default_cards';
import { defaultTypes } from '../../../data/defaultTypes';
import { defaultRarities } from '../../../data/defaultRarities';

// Component imports
import CardTemplate from './CardTemplate';
import DesktopFilterLayout from './DesktopFilterLayout';
import MobileFilterLayout from './MobileFilterLayout';
import DesktopProgressDisplay from './DesktopProgressDisplay';
import MobileProgressDisplay from './MobileProgressDisplay';
import DesktopSetInfoHeader from './DesktopSetInfoHeader';
import MobileSetInfoHeader from './MobileSetInfoHeader';
import ToggleButtons from './ToggleButtons';
import DesktopSetCardDisplay from './DesktopSetCardDisplay';
import MobileSetCardDisplay from './MobileSetCardDisplay';

// Styles
import '../../../css/filterSection.css';

type Props = {
    setInfo: {
        type?: string;
        setName: string;
        setSeries: string;
        setPrintRuns?: {
            firstEditionSet?: {
                buttonDisplay: string;
                description: string;
            };
            shadowlessSet?: {
                buttonDisplay: string;
                description: string;
            };
            unlimitedEditionSet?: {
                buttonDisplay: string;
                description: string;
            };
            fourthPrintSet?: {
                buttonDisplay: string;
                description: string;
            };
        };
        cardInfo: {
            numOfMainSet: number;
            setNumber: number;
            numOfReverseSet: number;
            numberOfSubSet: number;
        };
        showPriceData?: boolean;
        logo: string;
        icon?: string;
        releaseDate: string;
        description: string;
        printRunDescription?: string;
        showAllCards?: boolean;
        betaShowFullCard?: boolean;
        tcgPlayerSetLink?: string;
    };
};

type CheckBoxValues = {
    have: boolean;
    need: boolean;
    rarity: { [key: number]: boolean };
    type: { [key: number]: boolean };
};

function ScrollTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Fab
                onClick={handleClick}
                size="small"
                aria-label="scroll back to top"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}

const EnglishTemplate: React.FC<Props> = ({ setInfo }) => {
    ReactGA.initialize('G-677WMVXWLS');

    const { authParams, isAuthenticated } = useAuth();

    const [showCardTemplate, setShowCardTemplate] = useState<boolean>(false);
    const [selectedCard, setSelectedCard] = useState<any>(null);

    const changeCardSelectedStatus = (card: any) => {
        if (setInfo.betaShowFullCard) {
            let cardData = { ...card, ...setInfo };

            setSelectedCard(cardData);
            setShowCardTemplate(true);
        }
    };

    const location = useLocation();
    const dispatch = useDispatch();

    const isMobile = useMediaQuery('(max-width:789px)');

    const [currentView, setCurrentView] = useState('Unlimited');

    const [checkBoxValues, setCheckBoxValues] = useState<CheckBoxValues>({
        have: false,
        need: false,
        rarity: {},
        type: {},
    });

    const handleCheckBoxChange =
        (section: 'have' | 'need' | 'rarity' | 'type', key: string) =>
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setCheckBoxValues((prevValues) => {
                if (section === 'have' || section === 'need') {
                    return {
                        ...prevValues,
                        [section]: event.target.checked,
                    };
                } else {
                    return {
                        ...prevValues,
                        [section]: {
                            ...prevValues[section],
                            [key]: event.target.checked,
                        },
                    };
                }
            });
        };

    useEffect(() => {
        setCurrentView('Unlimited');
        setCheckBoxValues({
            have: false,
            need: false,
            rarity: Object.fromEntries(defaultRarities.map((rarity) => [rarity.id, false])),
            type: Object.fromEntries(defaultTypes.map((type) => [type.id, false])),
        });

        return () => {
            // Code to run when the component is unmounted (optional)
        };
    }, [location.pathname]);

    const type = setInfo.type;

    let showFirstEdition = setInfo.setPrintRuns?.firstEditionSet ? true : false;
    let showShadowlessSet = setInfo.setPrintRuns?.shadowlessSet ? true : false;
    let showfourthPrintSet = setInfo.setPrintRuns?.fourthPrintSet ? true : false;

    const setName = setInfo.setName;
    const printRun = useSelector((state: RootState) => state.printRun);
    const TCGState = useSelector((state: RootState) => state.TCGState);
    const seriesName = setInfo.setSeries;
    let displayCardsSet = currentView;

    const mainSet = setInfo.cardInfo?.numOfMainSet ?? 0;
    const reverseSet = setInfo.cardInfo?.numOfReverseSet ?? 0;
    const subSet = setInfo.cardInfo?.numberOfSubSet ?? 0;

    let showReverseSet = reverseSet > 0 ? true : false;
    let showSubSet = subSet > 0 ? true : false;

    const normaliseMainSet = (value: number) => ((value - 0) * 100) / (mainSet - 0);
    const normaliseReverseSet = (value: number) => ((value - 0) * 100) / (reverseSet - 0);
    const normaliseSubSet = (value: number) => ((value - 0) * 100) / (subSet - 0);

    const firstEditionSet = setInfo.setPrintRuns?.firstEditionSet
        ? setInfo.cardInfo.numOfMainSet
        : 0;
    const shadowlessSet = setInfo.setPrintRuns?.shadowlessSet ? setInfo.cardInfo.numOfMainSet : 0;
    const unlimitedSet = setInfo.setPrintRuns?.unlimitedEditionSet
        ? setInfo.cardInfo.numOfMainSet
        : 0;
    const fourthPrintSet = setInfo.setPrintRuns?.fourthPrintSet ? setInfo.cardInfo.numOfMainSet : 0;
    let totalSet = 0;

    const normaliseFirstEditionSet = (value: number) => ((value - 0) * 100) / (firstEditionSet - 0);
    const normaliseShadowlessSet = (value: number) => ((value - 0) * 100) / (shadowlessSet - 0);
    const normaliseUnlimitedSet = (value: number) => ((value - 0) * 100) / (unlimitedSet - 0);
    const normaliseFourthPrint = (value: number) => ((value - 0) * 100) / (fourthPrintSet - 0);

    let normaliseTotal;

    if (type === 'WOTC') {
        totalSet =
            firstEditionSet + shadowlessSet + unlimitedSet + fourthPrintSet + reverseSet + subSet;
        normaliseTotal = (value: number) => ((value - 0) * 100) / (totalSet - 0);
    } else {
        totalSet = mainSet + reverseSet + subSet;
        normaliseTotal = (value: number) => ((value - 0) * 100) / (totalSet - 0);
    }

    let [cards, setCards] = useState(defaultCards);

    useEffect(() => {
        dispatch({ type: 'SET_PAGE', payload: 'Set Beta' });
        dispatch({ type: 'SET_PRINTRUN', payload: 'Unlimited' });
        dispatch({ type: 'SET_SETNAME', payload: `${setInfo.setName}` });
        dispatch({ type: 'SET_SERIESNAME', payload: `${setInfo.setSeries}` });
        dispatch({ type: 'SET_SETNUMBEROFCARDS', payload: `${setInfo.cardInfo.setNumber}` });
        document.title = `MyPokellection | ${TCGState} | ${setInfo.setSeries} | ${setInfo.setName} Set`;
    }, [dispatch, setInfo.setName, setInfo.setSeries, setInfo.cardInfo.setNumber, TCGState]);

    useEffect(() => {
        let numUserCardsInCollection = cards.filter(
            (card) => card.isCardInCollection === true
        ).length;
        dispatch({ type: 'SET_USERSETCOLLECTEDCARDS', payload: numUserCardsInCollection });
    }, [cards, dispatch]);

    useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
    }, [dispatch]);

    useEffect(() => {
        const queryString = new URLSearchParams({
            setName,
            seriesName,
            TCGState,
            printRun,
        }).toString();
        fetch(`/api/getPokemonCards/?${queryString}`)
            .then((response) => response.json())
            .then((data) => {
                const sortedCards = data.sort(
                    (a: { cardsetindex: number }, b: { cardsetindex: number }) =>
                        a.cardsetindex - b.cardsetindex
                );
                setCards(sortedCards.map((card: any) => ({ ...card, isCardInCollection: false })));
            })
            .then(() => {
                if (isAuthenticated && authParams) {
                    const stringAuthParams = Object.fromEntries(
                        Object.entries(authParams).map(([key, value]) => [key, String(value)])
                    );

                    const queryParams = new URLSearchParams(stringAuthParams).toString();

                    const authParamsObj = Object.fromEntries(new URLSearchParams(queryParams));

                    const requestBody = {
                        ...authParamsObj,
                        setName,
                        seriesName,
                        TCGState,
                        printRun,
                    };

                    const queryString = new URLSearchParams(requestBody).toString();
                    fetch(`/api/getUserPokemonCards?${queryString}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((data: { id: number }[]) => {
                            const updatedCards = data.map((card) => ({
                                ...card,
                                isCardInCollection: true,
                            }));
                            setCards((prevCards) => {
                                const newCards = prevCards.map((c) => {
                                    const updatedCard = updatedCards.find((uc) => uc.id === c.id);
                                    return updatedCard
                                        ? {
                                              ...c,
                                              isCardInCollection: updatedCard.isCardInCollection,
                                          }
                                        : c;
                                });
                                return newCards;
                            });
                        })
                        .catch((error) => {
                            handleAPIResult({
                                success: false,
                                failed: true,
                                message: 'Failed: Unable to retrieve user cards',
                            });
                        });
                }
            })
            .catch((error) => console.error('Error in outer fetch:', error));
    }, [isAuthenticated, authParams, printRun, TCGState, seriesName, setName, currentView]);

    useEffect(() => {
        // Add this block to update rarity checkboxes
        const uniqueRarities = Array.from(
            new Set(cards.map((card) => card.rarityId?.toString() || '').filter(Boolean))
        );
        setCheckBoxValues((prev) => ({
            ...prev,
            rarity: Object.fromEntries(uniqueRarities.map((rarityId) => [rarityId, false])),
        }));
    }, [cards]);

    function changeCardCollectionStatus(
        cards: any[],
        card: {
            id: number;
            cardsetindex: number;
            number: number;
            name: string;
            imagelocation: string;
            isCardInCollection: boolean;
            market_price: number;
        }
    ): void {
        if (!isAuthenticated) {
            return;
        }

        const cardToUpdate = cards.find((c) => c.id === card.id);
        if (cardToUpdate && authParams) {
            const updatedCard = {
                ...cardToUpdate,
                isCardInCollection: !cardToUpdate.isCardInCollection,
            };
            setCards(cards.map((c) => (c.id === card.id ? updatedCard : c)));

            const stringAuthParams = Object.fromEntries(
                Object.entries(authParams).map(([key, value]) => [key, String(value)])
            );
            const queryParams = new URLSearchParams(stringAuthParams).toString();

            if (!cardToUpdate.isCardInCollection) {
                addCardToCollection(cardToUpdate.id, queryParams);
            } else {
                removeCardFromCollection(cardToUpdate.id, queryParams);
            }
        }
    }

    cards = cards.sort((a, b) => a.cardsetindex - b.cardsetindex);

    let collectedFirstEditionSet = cards.filter(
        (card) => card.isCardInCollection && card.editionId === 1
    ).length;
    let collectedShadowlessSet = cards.filter(
        (card) => card.isCardInCollection && card.editionId === 2
    ).length;
    let collectedUnlimitedSet = cards.filter(
        (card) =>
            card.isCardInCollection &&
            card.editionId === 3 &&
            !card.isReverseHolo &&
            !card.isPartOfSubSet
    ).length;
    let collectedFourthPrint = cards.filter(
        (card) => card.isCardInCollection && card.editionId === 4
    ).length;

    let collectedMainSet = cards.filter(
        (card) => card.isCardInCollection && !card.isReverseHolo && !card.isPartOfSubSet
    ).length;
    let collectedReverseHoloSet = cards.filter(
        (card) => card.isCardInCollection && card.isReverseHolo && !card.isPartOfSubSet
    ).length;
    let collectedSubSet = cards.filter(
        (card) => card.editionId === 3 && card.isCardInCollection && card.isPartOfSubSet
    ).length;
    let collectedCards;

    if (type === 'WOTC') {
        collectedCards =
            collectedFirstEditionSet +
            collectedShadowlessSet +
            collectedUnlimitedSet +
            collectedFourthPrint;
    } else {
        collectedCards = collectedMainSet + collectedReverseHoloSet + collectedSubSet;
    }

    // This useEffect will scroll to top only when setInfo.setName changes
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [setInfo.setName]);

    // Modified handleToggle function without scroll-to-top action
    const handleToggle = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        if (newAlignment !== null) {
            setCurrentView(newAlignment);
            // No scroll-to-top action here
        }
    };

    let cardsToDisplay = cards;

    if (type === 'WOTC' && setInfo.showAllCards !== true) {
        if (displayCardsSet === 'First Edition') {
            cardsToDisplay = cards.filter((card) => card.editionId === 1);
        } else if (displayCardsSet === 'Shadowless') {
            cardsToDisplay = cards.filter((card) => card.editionId === 2);
        } else if (displayCardsSet === 'Unlimited') {
            cardsToDisplay = cards.filter((card) => card.editionId === 3);
        } else if (displayCardsSet === '4th Print') {
            cardsToDisplay = cards.filter((card) => card.editionId === 4);
        }
    } else if (setInfo.showAllCards === true) {
        if (displayCardsSet === 'Unlimited') {
            cardsToDisplay = cards.filter((card) => !card.isReverseHolo && !card.isPartOfSubSet);
        } else if (displayCardsSet === 'Reverse Set') {
            cardsToDisplay = cards.filter((card) => card.isReverseHolo && !card.isPartOfSubSet);
        } else if (displayCardsSet === 'Sub-Set') {
            cardsToDisplay = cards.filter((card) => card.isPartOfSubSet);
        } else if (displayCardsSet === 'All Cards') {
            cardsToDisplay = cards;
        }
    } else {
        if (displayCardsSet === 'Unlimited') {
            cardsToDisplay = cards.filter((card) => !card.isReverseHolo && !card.isPartOfSubSet);
        } else if (displayCardsSet === 'Reverse Set') {
            cardsToDisplay = cards.filter((card) => card.isReverseHolo && !card.isPartOfSubSet);
        } else if (displayCardsSet === 'Sub-Set') {
            cardsToDisplay = cards.filter((card) => card.isPartOfSubSet);
        } else if (displayCardsSet === 'All Cards') {
            cardsToDisplay = cards;
        }
    }

    if (checkBoxValues.have && !checkBoxValues.need) {
        cardsToDisplay = cardsToDisplay.filter((card) => card.isCardInCollection);
    } else if (checkBoxValues.need && !checkBoxValues.have) {
        cardsToDisplay = cardsToDisplay.filter((card) => !card.isCardInCollection);
    }

    // Filter by rarity
    const selectedRarityIds = Object.entries(checkBoxValues.rarity)
        .filter(([_, isSelected]) => isSelected)
        .map(([rarityId]) => parseInt(rarityId));

    if (selectedRarityIds.length > 0) {
        cardsToDisplay = cardsToDisplay.filter((card) => selectedRarityIds.includes(card.rarityId));
    }

    // Filter by type
    const selectedTypeIds = Object.entries(checkBoxValues.type)
        .filter(([_, isSelected]) => isSelected)
        .map(([typeId]) => parseInt(typeId));

    if (selectedTypeIds.length > 0) {
        cardsToDisplay = cardsToDisplay.filter((card) => selectedTypeIds.includes(card.type_id));
    }

    const defaultLink =
        'https://tcgplayer.pxf.io/c/5054575/1808389/21018?u=https://www.tcgplayer.com/categories/trading-and-collectible-card-games/pokemon';
    let dynamicLink = '';
    if (setInfo.tcgPlayerSetLink) {
        dynamicLink = `https://tcgplayer.pxf.io/c/5054575/1808389/21018?u=${setInfo.tcgPlayerSetLink}`;
    } else {
        dynamicLink = defaultLink;
    }

    // Calculate unique rarities and types from the cards
    const uniqueRarities = useMemo(() => {
        return Array.from(new Set(cards.map((card) => card.rarityId)));
    }, [cards]);

    const uniqueTypes = useMemo(() => {
        const types = new Set(cards.map((card) => card.type_id));
        // Add Unknown type (id 0) if there are any cards with type_id 0 or undefined
        if (cards.some((card) => card.type_id === 0 || card.type_id === undefined)) {
            types.add(0);
        }
        return Array.from(types);
    }, [cards]);

    // Calculate the total filtered cards
    const totalFilteredCards = useMemo(() => {
        return cardsToDisplay.length;
    }, [cardsToDisplay]);

    return (
        <>
            <ToastContainer
                position="top-right"
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            {setInfo.betaShowFullCard && showCardTemplate && selectedCard && (
                <CardTemplate cardInfo={selectedCard} onClose={() => setShowCardTemplate(false)} />
            )}

            {isMobile ? (
                <>
                    <div style={{ marginBottom: '5px' }}>
                        <MobileSetInfoHeader
                            setInfo={setInfo}
                            dynamicLink={dynamicLink}
                            assets={assets}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <MobileProgressDisplay
                            showFirstEdition={showFirstEdition}
                            showShadowlessSet={showShadowlessSet}
                            showfourthPrintSet={showfourthPrintSet}
                            showReverseSet={showReverseSet}
                            showSubSet={showSubSet}
                            type={type as string}
                            collectedFirstEditionSet={collectedFirstEditionSet}
                            firstEditionSet={firstEditionSet}
                            collectedShadowlessSet={collectedShadowlessSet}
                            shadowlessSet={shadowlessSet}
                            collectedUnlimitedSet={collectedUnlimitedSet}
                            unlimitedSet={unlimitedSet}
                            collectedMainSet={collectedMainSet}
                            mainSet={mainSet}
                            collectedFourthPrint={collectedFourthPrint}
                            fourthPrintSet={fourthPrintSet}
                            collectedReverseHoloSet={collectedReverseHoloSet}
                            reverseSet={reverseSet}
                            collectedSubSet={collectedSubSet}
                            subSet={subSet}
                            collectedCards={collectedCards}
                            totalSet={totalSet}
                            normaliseFirstEditionSet={normaliseFirstEditionSet}
                            normaliseShadowlessSet={normaliseShadowlessSet}
                            normaliseUnlimitedSet={normaliseUnlimitedSet}
                            normaliseMainSet={normaliseMainSet}
                            normaliseFourthPrint={normaliseFourthPrint}
                            normaliseReverseSet={normaliseReverseSet}
                            normaliseSubSet={normaliseSubSet}
                            normaliseTotal={normaliseTotal}
                            currentView={currentView}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <MobileFilterLayout
                            checkBoxValues={checkBoxValues}
                            handleCheckBoxChange={handleCheckBoxChange}
                            uniqueRarities={uniqueRarities}
                            uniqueTypes={uniqueTypes}
                            totalFilteredCards={totalFilteredCards}
                        />
                    </div>
                </>
            ) : (
                <>
                    <div style={{ marginBottom: '5px' }}>
                        <DesktopSetInfoHeader
                            setInfo={setInfo}
                            dynamicLink={dynamicLink}
                            assets={assets}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <DesktopProgressDisplay
                            showFirstEdition={showFirstEdition}
                            showShadowlessSet={showShadowlessSet}
                            showfourthPrintSet={showfourthPrintSet}
                            showReverseSet={showReverseSet}
                            showSubSet={showSubSet}
                            type={type as string}
                            collectedFirstEditionSet={collectedFirstEditionSet}
                            firstEditionSet={firstEditionSet}
                            collectedShadowlessSet={collectedShadowlessSet}
                            shadowlessSet={shadowlessSet}
                            collectedUnlimitedSet={collectedUnlimitedSet}
                            unlimitedSet={unlimitedSet}
                            collectedMainSet={collectedMainSet}
                            mainSet={mainSet}
                            collectedFourthPrint={collectedFourthPrint}
                            fourthPrintSet={fourthPrintSet}
                            collectedReverseHoloSet={collectedReverseHoloSet}
                            reverseSet={reverseSet}
                            collectedSubSet={collectedSubSet}
                            subSet={subSet}
                            collectedCards={collectedCards}
                            totalSet={totalSet}
                            normaliseFirstEditionSet={normaliseFirstEditionSet}
                            normaliseShadowlessSet={normaliseShadowlessSet}
                            normaliseUnlimitedSet={normaliseUnlimitedSet}
                            normaliseMainSet={normaliseMainSet}
                            normaliseFourthPrint={normaliseFourthPrint}
                            normaliseReverseSet={normaliseReverseSet}
                            normaliseSubSet={normaliseSubSet}
                            normaliseTotal={normaliseTotal}
                            currentView={currentView}
                        />
                    </div>
                    <div style={{ marginBottom: '5px' }}>
                        <DesktopFilterLayout
                            checkBoxValues={checkBoxValues}
                            handleCheckBoxChange={handleCheckBoxChange}
                            uniqueRarities={uniqueRarities}
                            uniqueTypes={uniqueTypes}
                            totalFilteredCards={totalFilteredCards}
                        />
                    </div>
                </>
            )}

            <div style={{ marginBottom: '5px' }}>
                <ToggleButtons
                    setInfo={setInfo}
                    select={currentView}
                    handleToggle={handleToggle}
                    showFirstEdition={showFirstEdition}
                    showShadowlessSet={showShadowlessSet}
                    showfourthPrintSet={showfourthPrintSet}
                    showReverseSet={showReverseSet}
                    showSubSet={showSubSet}
                    type={type}
                />
            </div>

            <div>
                {isMobile ? (
                    <MobileSetCardDisplay
                        cardsToDisplay={cardsToDisplay}
                        setInfo={setInfo}
                        assets={assets}
                        showPriceData={setInfo.showPriceData || false}
                        changeCardSelectedStatus={changeCardSelectedStatus}
                        changeCardCollectionStatus={changeCardCollectionStatus}
                    />
                ) : (
                    <DesktopSetCardDisplay
                        cardsToDisplay={cardsToDisplay}
                        setInfo={setInfo}
                        assets={assets}
                        showPriceData={setInfo.showPriceData || false}
                        changeCardSelectedStatus={changeCardSelectedStatus}
                        changeCardCollectionStatus={changeCardCollectionStatus}
                    />
                )}
            </div>

            <ScrollTop />
        </>
    );
};

export default EnglishTemplate;