import { toast } from 'react-toastify';

interface UserApiResponse {
    success: boolean;
    exists?: boolean;
    message?: string;
}

interface FavoritePokemonResponse extends UserApiResponse {
    favoritePokemon?: {
        name: string;
        image: string;
        number: number;
        gen: number;
    };
}

interface UserDetailsResponse extends UserApiResponse {
    userDetails?: any; // Replace 'any' with a more specific type if you know the structure of userDetails
}

export const checkUsername = async (userName: string): Promise<UserApiResponse> => {
    try {
        const queryString = new URLSearchParams({ userName }).toString();
        const response = await fetch(`/api/checkUsername/?${queryString}`);
        const result: UserApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to check username');
        }

        return result;
    } catch (error) {
        console.error('Error checking username:', error);
        toast.error('An error occurred while checking username');
        return { success: false, exists: false };
    }
};

export const updateUserCountry = async (
    authParams: any,
    countryCode: string
): Promise<UserApiResponse> => {
    try {
        const response = await fetch('/api/updateUserCountry', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...authParams,
                countryCode,
            }),
        });

        const result: UserApiResponse = await response.json();

        if (!result.success) {
            toast.error('Failed to update country');
        }

        return result;
    } catch (error) {
        console.error('Error updating country:', error);
        toast.error('An error occurred while updating country');
        return { success: false };
    }
};

export const getUserDetails = async (authParams: any, username: string): Promise<UserDetailsResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const queryParams = new URLSearchParams({
            ...stringAuthParams,
            userName: username,
        }).toString();

        const response = await fetch(`/api/getUserDetails?${queryParams}`);
        const result: UserDetailsResponse = await response.json();

        if (!result.success) {
            console.error('Failed to fetch user details:', result.message || 'Unknown error');
            toast.error('Failed to fetch user details');
        }

        return result;
    } catch (error) {
        console.error('An error occurred while fetching user details:', error);
        toast.error('An error occurred while fetching user details');
        return { success: false };
    }
};

export const getFavoritePokemon = async (username: string): Promise<FavoritePokemonResponse> => {
    try {
        const queryString = new URLSearchParams({ username }).toString();
        const response = await fetch(`/api/getFavoritePokemon?${queryString}`);
        const result: FavoritePokemonResponse = await response.json();

        if (!result.success) {
            console.error('Failed to fetch favorite Pokémon:', result.message || 'Unknown error');
        }

        return result;
    } catch (error) {
        console.error('An error occurred while fetching favorite Pokémon:', error);
        return { success: false };
    }
};

export const updateFavoritePokemon = async (authParams: any, pokemonId: number): Promise<UserApiResponse> => {
    try {
        const stringAuthParams = Object.fromEntries(
            Object.entries(authParams).map(([key, value]) => [key, String(value)])
        );

        const response = await fetch('/api/updateFavoritePokemon', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                ...stringAuthParams,
                pokemonId,
            }),
        });

        const result: UserApiResponse = await response.json();

        if (result.success) {
            toast.success('Favorite Pokémon updated successfully');
        } else {
            toast.error('Failed to update favorite Pokémon');
        }

        return result;
    } catch (error) {
        console.error('Error updating favorite Pokémon:', error);
        toast.error('An error occurred while updating favorite Pokémon');
        return { success: false };
    }
};
