import React, { useState, useMemo, useEffect } from 'react';
import { Grid, useMediaQuery, Paper, Fab, useScrollTrigger, Zoom } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import DesktopPokedexHeader from './DesktopPokedexHeader';
import MobilePokedexHeader from './MobilePokedexHeader';
import DesktopFavoritePokemon from './DesktopFavoritePokemon';
import MobileFavoritePokemon from './MobileFavoritePokemon';
import DesktopPokedexFilter from './DesktopPokedexFilter';
import DesktopPokemonCard from './DesktopPokemonCard';
import MobilePokemonCard from './MobilePokemonCard';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useAuth } from '../../context/AuthContext';
import { useUserFunctions } from '../../hooks/useUserFunctions';
import { RootState } from '../../redux/store'; // Make sure this path is correct
import { useCardFunctions } from '../../hooks/useCardFunctions';

// Testing variables - comment out before deploying to production
const TEST_MODE = false; // Changed to true
const TEST_USER = {
    name: 'TestUser',
    isAuthenticated: true,
    favoritePokemon: {
        name: 'Pikachu',
        image: 'https://raw.githubusercontent.com/PokeAPI/sprites/master/sprites/pokemon/25.png',
        number: 25,
        gen: 1,
    },
};

const hardcodedPokemon = [
    { id: 1, name: 'Bulbasaur', type: 'Grass/Poison', gen: 1 },
    { id: 4, name: 'Charmander', type: 'Fire', gen: 1 },
    { id: 7, name: 'Squirtle', type: 'Water', gen: 1 },
    { id: 25, name: 'Pikachu', type: 'Electric', gen: 1 },
    { id: 26, name: 'Raichu', type: 'Electric', gen: 1 },
    { id: 152, name: 'Chikorita', type: 'Grass', gen: 2 },
    { id: 252, name: 'Treecko', type: 'Grass', gen: 3 },
    { id: 387, name: 'Turtwig', type: 'Grass', gen: 4 },
    { id: 495, name: 'Snivy', type: 'Grass', gen: 5 },
    { id: 650, name: 'Chespin', type: 'Grass', gen: 6 },
    { id: 722, name: 'Rowlet', type: 'Grass/Flying', gen: 7 },
    { id: 810, name: 'Grookey', type: 'Grass', gen: 8 },
    { id: 906, name: 'Sprigatito', type: 'Grass', gen: 9 },
];

function ScrollTop() {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <Zoom in={trigger}>
            <Fab
                onClick={handleClick}
                size="small"
                aria-label="scroll back to top"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
}

const MyPokedex: React.FC = () => {
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:789px)');
    const [selectedGen, setSelectedGen] = useState<number>(1);  // Default to Gen 1
    const [filterExpanded, setFilterExpanded] = useState(true);
    const { authParams } = useAuth();
    console.log('authParams in MyPokedex:', authParams);
    // Use Redux to get the favorite Pokémon ID and authentication state
    const userName = useSelector((state: RootState) => state.auth.userName);
    const reduxIsAuthenticated = useSelector((state: RootState) => state.auth.authenticated);
    const [favoritePokemon, setFavoritePokemon] = useState(null);

    const { getFavoritePokemon } = useUserFunctions();
    const effectiveUserName = TEST_MODE ? TEST_USER.name : userName || 'Guest';
    const { 
        featuredPokemonCards, 
        cameoPokemonCards, 
        caughtPokemon, 
        featuredCaughtPokemonCards, 
        cameoCaughtPokemonCards, 
        pokedexValue,
        cardLoading
    } = useCardFunctions(effectiveUserName, reduxIsAuthenticated);
    
    // Use the TEST_USER when TEST_MODE is true
    const effectiveIsAuthenticated = TEST_MODE ? TEST_USER.isAuthenticated : reduxIsAuthenticated;
    const effectiveFavoritePokemon = TEST_MODE ? TEST_USER.favoritePokemon : favoritePokemon;

    useEffect(() => {
        const fetchFavoritePokemon = async () => {
            console.log('Fetching favorite Pokemon...');
            if (effectiveIsAuthenticated) {
                try {
                    const favorite = await getFavoritePokemon(effectiveUserName);
                    console.log('API response:', favorite);
                    setFavoritePokemon(favorite as any);
                } catch (error) {
                    console.error('Error fetching favorite Pokemon:', error);
                }
            } else {
                console.log('Not authenticated or no favorite Pokemon ID');
            }
        };
        fetchFavoritePokemon();
    }, [effectiveIsAuthenticated, effectiveUserName, getFavoritePokemon]);

    console.log('Current favorite Pokemon:', favoritePokemon);

    const filteredPokemon = useMemo(() => {
        return hardcodedPokemon.filter(pokemon => pokemon.gen === selectedGen);
    }, [selectedGen]);

    const handleGenChange = (event: React.MouseEvent<HTMLElement>, newGen: number | null) => {
        if (newGen !== null) {
            setSelectedGen(newGen);
        }
    };

    const toggleFilterExpanded = () => {
        setFilterExpanded(!filterExpanded);
    };

    const headerData = {
        userName: effectiveUserName || 'Guest',
        totalPokemon: 1025,
        caughtPokemon: caughtPokemon,
        caughtFeaturedCards: featuredCaughtPokemonCards,
        caughtCameoCards: cameoCaughtPokemonCards,
        totalFeaturedCards: featuredPokemonCards,
        totalCameoCards: cameoPokemonCards,
        totalMarketValue: pokedexValue,
        cardLoading: cardLoading,
        isAuthenticated: effectiveIsAuthenticated,
    };

    // Temporary hard-coded data for favoritePokemonData
    const tempCollectedFavoritePokemonCards = 25;
    const tempTotalFavoritePokemonFeaturedCards = 50;
    const tempCollectedFavoritePokemonFeaturedCards = 15;
    const tempTotalFavoritePokemonCameoCards = 30;
    const tempCollectedFavoritePokemonCameoCards = 10;

    const defaultFavoritePokemon = {
        name: 'Unknown',
        image: '',
        number: 0,
        gen: 0
    };

    const favoritePokemonData = {
        favoritePokemon: effectiveFavoritePokemon || defaultFavoritePokemon,
        collectedCards: tempCollectedFavoritePokemonCards,
        totalFeaturedCards: tempTotalFavoritePokemonFeaturedCards,
        collectedFeaturedCards: tempCollectedFavoritePokemonFeaturedCards,
        totalCameoCards: tempTotalFavoritePokemonCameoCards,
        collectedCameoCards: tempCollectedFavoritePokemonCameoCards
    };

    console.log('Header data:', headerData);

    const PokedexHeader = isMobile ? MobilePokedexHeader : DesktopPokedexHeader;
    const FavoritePokemon = isMobile ? MobileFavoritePokemon : DesktopFavoritePokemon;
    const PokemonCard = isMobile ? MobilePokemonCard : DesktopPokemonCard;

    const handlePokemonClick = (pokemonId: number) => {
        navigate(`/MyPokedex/${pokemonId}`);
    };

    return (
        <>
            <div style={{ marginBottom: '5px' }}>
                <PokedexHeader {...headerData} />
            </div>
            {effectiveIsAuthenticated && (
                <div style={{ marginBottom: '5px' }}>
                    <Paper>
                        <Grid item xs={12} sm={4}>
                            <FavoritePokemon 
                                {...favoritePokemonData} 
                            />
                        </Grid>
                    </Paper>
                </div>
            )}
            <div style={{ marginBottom: '5px' }}>
                <DesktopPokedexFilter
                    selectedGen={selectedGen}
                    handleGenChange={handleGenChange}
                    totalFilteredPokemon={filteredPokemon.length}
                    expanded={filterExpanded}
                    toggleExpanded={toggleFilterExpanded}
                />
            </div>
            <div style={{ marginBottom: '5px' }}>
                <Grid container spacing={2}>
                    {filteredPokemon.map((pokemon) => (
                        <Grid item xs={6} sm={4} md={3} lg={2} key={pokemon.id}>
                            <div onClick={() => handlePokemonClick(pokemon.id)}>
                                <PokemonCard
                                    pokemon={pokemon}
                                    caughtFeatureCards={Math.floor(Math.random() * 10)} // Replace with actual data
                                    caughtCameoCards={Math.floor(Math.random() * 5)} // Replace with actual data
                                    hasCaught={Math.random() > 0.5} // Replace with actual data
                                />
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </div>
            <ScrollTop />
        </>
    );
};

export default MyPokedex;
