import { useState, useEffect, useCallback } from 'react';
import * as cardApi from '../api/cardApi';
import { useAuth } from '../context/AuthContext';

export const useCardFunctions = (username: string, isAuthenticated: boolean) => {
    const { authParams } = useAuth();

    const [featuredPokemonCards, setFeaturedPokemonCards] = useState(0);
    const [cameoPokemonCards, setCameoPokemonCards] = useState(0);
    const [caughtPokemon, setCaughtPokemon] = useState(0);
    const [featuredCaughtPokemonCards, setFeaturedCaughtPokemonCards] = useState(0);
    const [cameoCaughtPokemonCards, setCameoCaughtPokemonCards] = useState(0);
    const [pokedexValue, setPokedexValue] = useState(0);
    const [cardLoading, setCardLoading] = useState(false);

    const fetchCardCounts = useCallback(async () => {
        console.log('fetchCardCounts called with username:', username);
        console.log('authParams in fetchCardCounts:', authParams);
        console.log('isAuthenticated:', isAuthenticated);

        setCardLoading(true);
        try {
            const [featuredResult, cameoResult] = await Promise.all([
                cardApi.getFeaturedPokemonCardCount(),
                cardApi.getCameoPokemonCardCount(),
            ]);

            if (featuredResult.success) {
                setFeaturedPokemonCards(featuredResult.featuredPokemonCards || 0);
            }
            if (cameoResult.success) {
                setCameoPokemonCards(cameoResult.cameoPokemonCards || 0);
            }

            if (isAuthenticated && authParams && username) {
                const [
                    caughtResult, 
                    featuredCaughtResult, 
                    cameoCaughtResult,
                    pokedexValueResult
                ] = await Promise.all([
                    cardApi.getUserCaughtPokemon(username),
                    cardApi.getFeaturedCaughtPokemonCardCount(username),
                    cardApi.getCameoCaughtPokemonCardCount(username),
                    cardApi.getUserPokedexValue(authParams, username)
                ]);

                if (caughtResult.success) {
                    setCaughtPokemon(caughtResult.caughtPokemon || 0);
                }
                if (featuredCaughtResult.success) {
                    setFeaturedCaughtPokemonCards(featuredCaughtResult.featuredCaughtPokemonCards || 0);
                }
                if (cameoCaughtResult.success) {
                    setCameoCaughtPokemonCards(cameoCaughtResult.cameoCaughtPokemonCards || 0);
                }
                if (pokedexValueResult.success) {
                    setPokedexValue(pokedexValueResult.pokedexValue || 0);
                }
            } else {
                console.log('Skipping authenticated API calls because user is not authenticated or missing authParams/username');
            }
        } catch (error) {
            console.error('Error in fetchCardCounts:', error);
        } finally {
            setCardLoading(false);
        }
    }, [username, authParams, isAuthenticated]);

    useEffect(() => {
        console.log('useEffect in useCardFunctions triggered');
        fetchCardCounts();
    }, [fetchCardCounts]);

    return {
        featuredPokemonCards,
        cameoPokemonCards,
        caughtPokemon,
        featuredCaughtPokemonCards,
        cameoCaughtPokemonCards,
        pokedexValue,
        cardLoading,
        refreshCardCounts: fetchCardCounts,
    };
};
