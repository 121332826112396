import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Typography, Paper, Box, Button } from '@mui/material';
import SetCardDisplay from '../English/Template/DesktopSetCardDisplay';

// Hardcoded data for featured and cameo cards
const hardcodedCards = {
    featuredCards: [
        { id: 1, name: 'Pikachu V', number: '43', imagelocation: 'https://example.com/pikachu-v.jpg', market_price: 5.99, isCardInCollection: true },
        { id: 2, name: 'Pikachu VMAX', number: '44', imagelocation: 'https://example.com/pikachu-vmax.jpg', market_price: 15.99, isCardInCollection: false },
        // Add more featured cards as needed
    ],
    cameoCards: [
        { id: 3, name: 'Ash\'s Pikachu', number: '56', imagelocation: 'https://example.com/ash-pikachu.jpg', market_price: 2.99, isCardInCollection: true },
        { id: 4, name: 'Pikachu & Zekrom GX', number: '33', imagelocation: 'https://example.com/pikachu-zekrom.jpg', market_price: 8.99, isCardInCollection: false },
        // Add more cameo cards as needed
    ],
};

const PokemonDetail: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const pokemon = { id: parseInt(id || '0'), name: 'Pikachu', type: 'Electric', gen: 1 }; // Replace with actual data fetching later

    // Placeholder functions
    const changeCardSelectedStatus = (card: any) => {
        console.log('Card selected:', card);
    };

    const changeCardCollectionStatus = (cards: any[], card: any) => {
        console.log('Card collection status changed:', card);
    };

    return (
        <Box sx={{ p: 2 }}>
            <Button component={Link} to="/mypokedex" variant="contained" sx={{ mb: 2 }}>
                Back to Pokédex
            </Button>
            <Paper elevation={3} sx={{ p: 2, mb: 2 }}>
                <Typography variant="h4" gutterBottom>{pokemon.name}</Typography>
                <Typography variant="body1">Type: {pokemon.type}</Typography>
                <Typography variant="body1">Generation: {pokemon.gen}</Typography>
            </Paper>

            <Typography variant="h5" gutterBottom>Featured Cards</Typography>
            <SetCardDisplay
                cardsToDisplay={hardcodedCards.featuredCards}
                setInfo={{ cardInfo: { setNumber: hardcodedCards.featuredCards.length } }}
                assets={{ site: { images: { backupImage: 'path/to/backup/image.jpg', pokeballCaught: 'path/to/pokeball/caught.png', pokeball: 'path/to/pokeball.png' } } }}
                showPriceData={true}
                changeCardSelectedStatus={changeCardSelectedStatus}
                changeCardCollectionStatus={changeCardCollectionStatus}
            />

            <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>Cameo Cards</Typography>
            <SetCardDisplay
                cardsToDisplay={hardcodedCards.cameoCards}
                setInfo={{ cardInfo: { setNumber: hardcodedCards.cameoCards.length } }}
                assets={{ site: { images: { backupImage: 'path/to/backup/image.jpg', pokeballCaught: 'path/to/pokeball/caught.png', pokeball: 'path/to/pokeball.png' } } }}
                showPriceData={true}
                changeCardSelectedStatus={changeCardSelectedStatus}
                changeCardCollectionStatus={changeCardCollectionStatus}
            />
        </Box>
    );
};

export default PokemonDetail;