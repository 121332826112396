import { useState, useCallback } from 'react';
import { useAuth } from '../context/AuthContext';
import * as userApi from '../api/userApi';

export const useUserFunctions = () => {
    const { authParams } = useAuth();
    const [loading, setLoading] = useState(false);

    const getUserDetails = useCallback(
        async (username: string) => {
            if (!authParams) {
                console.error('authParams is null or undefined');
                return null;
            }

            setLoading(true);
            try {
                const result = await userApi.getUserDetails(authParams, username);
                return result.success && result.userDetails ? result.userDetails : null;
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const updateFavoritePokemon = useCallback(
        async (pokemonId: number) => {
            if (!authParams) return false;

            setLoading(true);
            try {
                const result = await userApi.updateFavoritePokemon(authParams, pokemonId);
                return result.success;
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const checkUsername = useCallback(async (userName: string): Promise<boolean> => {
        setLoading(true);
        try {
            const result = await userApi.checkUsername(userName);
            return result.success && result.exists === true;
        } finally {
            setLoading(false);
        }
    }, []);

    const updateUserCountry = useCallback(
        async (countryCode: string) => {
            if (!authParams) return false;

            setLoading(true);
            try {
                const result = await userApi.updateUserCountry(authParams, countryCode);
                return result.success;
            } finally {
                setLoading(false);
            }
        },
        [authParams]
    );

    const getFavoritePokemon = useCallback(async (username: string) => {
        console.log('getFavoritePokemon called with username:', username);
        setLoading(true);
        try {
            const result = await userApi.getFavoritePokemon(username);
            console.log('getFavoritePokemon API result:', result);
            return result.success ? result.favoritePokemon : null;
        } catch (error) {
            console.error('Error in getFavoritePokemon:', error);
            return null;
        } finally {
            setLoading(false);
        }
    }, []);

    return { getUserDetails, updateFavoritePokemon, checkUsername, updateUserCountry, getFavoritePokemon, loading };
};
