import React, { useState } from 'react';
import { Typography, Avatar, Box, Grid, Paper, IconButton, Collapse } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface FavoritePokemonProps {
  favoritePokemon: {
    name: string;
    image: string;
    number: number;
    gen: number;
  };
  collectedCards: number;
  totalFeaturedCards: number;
  collectedFeaturedCards: number;
  totalCameoCards: number;
  collectedCameoCards: number;
}

const DesktopFavoritePokemon: React.FC<FavoritePokemonProps> = ({
  favoritePokemon,
  collectedCards,
  totalFeaturedCards,
  collectedFeaturedCards,
  totalCameoCards,
  collectedCameoCards,
}) => {
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Paper elevation={4} style={{ width: '100%', padding: '16px' }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" mr={2}>
            Favorite Pokémon
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            Generation: {favoritePokemon.gen} | {favoritePokemon.name} #{favoritePokemon.number}
          </Typography>
        </Box>
        <IconButton onClick={toggleExpanded} size="small">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Box>
      <Collapse in={expanded}>
        <Box display="flex" alignItems="center">
          <Avatar
            src={favoritePokemon.image}
            alt={favoritePokemon.name}
            sx={{ width: 80, height: 80, mr: 2 }}
          />
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3}>
              <Typography variant="body2">Total Cards</Typography>
              <Typography variant="body1">{collectedCards}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">Featured Cards</Typography>
              <Typography variant="body1">{collectedFeaturedCards}/{totalFeaturedCards}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2">Cameo Cards</Typography>
              <Typography variant="body1">{collectedCameoCards}/{totalCameoCards}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </Paper>
  );
};

export default DesktopFavoritePokemon;
