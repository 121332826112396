import React from 'react';
import { Paper } from '@mui/material';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface DesktopSetCardDisplayProps {
    cardsToDisplay: any[];
    setInfo: any;
    assets: any;
    showPriceData: boolean;
    changeCardSelectedStatus: (card: any) => void;
    changeCardCollectionStatus: (cards: any[], card: any) => void;
}

const DesktopSetCardDisplay: React.FC<DesktopSetCardDisplayProps> = ({
    cardsToDisplay,
    setInfo,
    assets,
    showPriceData,
    changeCardSelectedStatus,
    changeCardCollectionStatus,
}) => {
    return (
        <div className="desktop-set-cards-container">
            {cardsToDisplay &&
                cardsToDisplay.map((card) => (
                    <div key={card.id} className="desk-card-container">
                        <Paper elevation={4}
                              sx={{ 
                                '&:hover': {
                                  boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2)',
                                  transform: 'translateY(-4px)',
                                }
                              }}>
                            <div className="desktop-card-box">
                                <p className="mobile-centered-text">{card.name}</p>
                                <LazyLoadImage
                                    className="card-image"
                                    src={card.imagelocation || assets.site.images.backupImage}
                                    alt={card.name}
                                    effect="blur"
                                    width="100%"
                                    height="auto"
                                    style={{
                                        objectFit: 'contain',
                                        maxWidth: '100%',
                                        maxHeight: '100%',
                                    }}
                                    onClick={() => changeCardSelectedStatus(card)}
                                />
                                <div className="card-footer">
                                    <div className="desktop-card-number">
                                        <p>
                                            {card.number}/{setInfo.cardInfo.setNumber}
                                        </p>
                                    </div>
                                    {showPriceData && (
                                        <div className="card-price">
                                            <p>${card.market_price.toFixed(2)}</p>
                                        </div>
                                    )}
                                    {!showPriceData && (
                                        <div className="card-price">
                                            <p></p>
                                        </div>
                                    )}
                                    <img
                                        loading="lazy"
                                        className="desktop-pokeball"
                                        src={
                                            card.isCardInCollection
                                                ? assets.site.images.pokeballCaught
                                                : assets.site.images.pokeball
                                        }
                                        alt=""
                                        onClick={() =>
                                            changeCardCollectionStatus(cardsToDisplay, card)
                                        }
                                    />
                                </div>
                            </div>
                        </Paper>
                    </div>
                ))}
        </div>
    );
};

export default DesktopSetCardDisplay;
