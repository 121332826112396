import React, { createContext, useContext, useState, useEffect } from 'react';

interface AuthContextType {
    authParams: any;
    setAuthParams: (params: any) => void;
    isAuthenticated: boolean;
    setIsAuthenticated: (value: boolean) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [authParams, setAuthParams] = useState<any>(null);
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        // Check local storage for existing auth data on component mount
        const storedAuthParams = localStorage.getItem('authParams');
        if (storedAuthParams) {
            setAuthParams(JSON.parse(storedAuthParams));
            setIsAuthenticated(true);
        }
    }, []);

    const updateAuthParams = (params: any) => {
        setAuthParams(params);
        localStorage.setItem('authParams', JSON.stringify(params));
        setIsAuthenticated(true);
    };

    return (
        <AuthContext.Provider
            value={{
                authParams,
                setAuthParams: updateAuthParams,
                isAuthenticated,
                setIsAuthenticated,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
