import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../css/login.css';
import ReactGA from 'react-ga4';
import { Container, Grid, Paper, Avatar, Button } from '@mui/material';
import Typography from '@mui/joy/Typography';
import { styled } from '@mui/system';
import Flag from 'react-country-flag';
import assets from '../../assets';
import { Sheet } from '@mui/joy';
import '../../css/cardTemplate.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useFollowFunctions } from '../../hooks/useFollowFunctions';
import { useFriendFunctions } from '../../hooks/useFriendFunctions';
import { useUserFunctions } from '../../hooks/useUserFunctions';
import EditProfilePopup from '../../components/userProfile/EditProfilePopup';
import { setFavoritePokemonImage } from '../../redux/slices/authSlice';
import { useAuth } from '../../context/AuthContext';

const StyledContainer = styled(Container)({
    padding: '20px',
});

const StyledPaper = styled(Paper)({
    padding: '20px',
    textAlign: 'center',
    color: 'black',
});

const StyledAvatar = styled(Avatar)({
    width: 100,
    height: 100,
    margin: 'auto',
});

const UserProfile: React.FC = () => {
    ReactGA.initialize('G-677WMVXWLS');

    const { userName } = useParams<{ userName?: string }>();
    const dispatch = useDispatch();
    const authState = useSelector((state: any) => state.auth);
    const [isEditProfileOpen, setIsEditProfileOpen] = useState(false);
    const [joinDate, setJoinDate] = useState<string>('');
    const [countryCode, setCountryCode] = useState<string>('CA');

    const [userExists, setUserExists] = useState(false);
    const [loading, setLoading] = useState(true);
    const [dataLoaded, setDataLoaded] = useState(false);

    const { isFollowing, followerCount, followLoading, handleFollowUser, handleUnfollowUser } =
        useFollowFunctions(userName);

    const {
        friendStatus,
        friendCount,
        isRequester,
        friendLoading,
        handleFriendAction,
        handleFriendResponse,
    } = useFriendFunctions(userName);

    const { getUserDetails, checkUsername, loading: userLoading } = useUserFunctions();
    const [userDetails, setUserDetails] = useState<any>(null);

    const [isCurrentUser, setIsCurrentUser] = useState(false);

    const { authParams } = useAuth();

    const checkIsCurrentUser = useCallback(() => {
        const currentUserName = authState.userName || localStorage.getItem('userName');
        if (currentUserName && userName) {
            setIsCurrentUser(currentUserName === userName);
        }
    }, [authState.userName, userName]);

    useEffect(() => {
        checkIsCurrentUser();
    }, [checkIsCurrentUser]);

    const fetchUserDetails = useCallback(async () => {
        if (userName) {
            setLoading(true);
            try {
                const details = await getUserDetails(userName);
                if (details) {
                    setUserDetails(details);
                    setCountryCode(details.countryCode || 'CA');
                    checkIsCurrentUser();

                    if (isCurrentUser && details.favoritePokemonImage) {
                        dispatch(setFavoritePokemonImage(details.favoritePokemonImage));
                    }
                } else {
                    console.error('User details are null');
                    toast.error('Failed to fetch user details. Please try refreshing the page.');
                }
            } catch (error) {
                console.error('Error fetching user details:', error);
                toast.error('An error occurred while fetching user details.');
            } finally {
                setLoading(false);
                setDataLoaded(true);
            }
        } else {
            console.log('No userName provided, skipping fetchUserDetails');
        }
    }, [userName, getUserDetails, checkIsCurrentUser, isCurrentUser, dispatch]);

    useEffect(() => {
        if (!userName) {
            console.log('No userName provided, skipping username check');
            return;
        }

        const checkUsernameExists = async () => {
            const exists = await checkUsername(userName);
            setUserExists(exists);
            if (exists) {
                if (isCurrentUser) {
                    if (authParams) {
                        fetchUserDetails();
                    } else {
                        setLoading(false);
                        toast.error('Authentication error. Please try logging in again.');
                    }
                } else {
                    fetchUserDetails();
                }
            } else {
                setLoading(false);
            }
        };

        checkUsernameExists();
    }, [userName, checkUsername, fetchUserDetails, authParams, isCurrentUser]);

    useEffect(() => {
        if (dataLoaded && !userDetails) {
            toast.error('User details are not available. Please try refreshing the page.');
        }
    }, [dataLoaded, userDetails]);

    const handleEditProfile = () => {
        if (userDetails) {
            setIsEditProfileOpen(true);
        } else {
            toast.error('User details are not available. Please try refreshing the page.');
        }
    };

    const handleCloseEditProfile = () => {
        setIsEditProfileOpen(false);
    };

    const handleSaveProfile = async (pokemonId: number, newCountryCode: string) => {
        setIsEditProfileOpen(false);
        await fetchUserDetails();
    };

    useEffect(() => {
        ReactGA.send(window.location.pathname + window.location.search);
    }, []);

    useEffect(() => {
        if (!userName) return;

        dispatch({ type: 'SET_PAGE', payload: 'UserProfile' });
    }, [userName, dispatch]);

    useEffect(() => {
        if (!userName) return;

        const fetchUserJoinDate = async () => {
            try {
                const queryString = new URLSearchParams({ userName }).toString();
                const response = await fetch(`/api/getUserJoinDate?${queryString}`);
                const result = await response.json();

                if (result.success) {
                    const date = new Date(result.joinDate);
                    setJoinDate(
                        date.toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric',
                        })
                    );
                } else {
                    toast.error('Failed to fetch user join date');
                }
            } catch (error) {
                toast.error('An error occurred while fetching user join date');
                console.error('Error fetching user join date:', error);
            }
        };

        fetchUserJoinDate();
    }, [userName]);

    if (loading || userLoading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                <CircularProgress size={40} />
            </Box>
        );
    }

    if (!userExists) {
        return (
            <Typography level="h4" component="div" textAlign="center">
                User does not exist
            </Typography>
        );
    }

    return (
        <>
            <ToastContainer
                position="top-right"
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />

            <StyledContainer>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <StyledPaper>
                            <StyledAvatar
                                alt="Profile Pic"
                                src={
                                    isCurrentUser
                                        ? authState.favoritePokemonImage
                                        : userDetails?.favoritePokemonImage ||
                                          assets.pokedex.pokemon.PK123
                                }
                            />
                            <Typography level="h4" fontWeight="lg">
                                {userName}
                            </Typography>
                            <Sheet
                                sx={{
                                    bgcolor: 'background.level2',
                                    borderRadius: 'sm',
                                    p: 1.5,
                                    my: 1.5,
                                    display: 'flex',
                                    gap: 2,
                                    '& > div': { flex: 1 },
                                }}
                            >
                                <div>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Joined Date
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        {joinDate || 'Loading...'}
                                    </Typography>
                                </div>
                                <div>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Country
                                    </Typography>
                                    <Flag
                                        countryCode={countryCode}
                                        svg
                                        style={{ width: '24px', height: '24px' }}
                                    />
                                </div>
                            </Sheet>
                            <Sheet
                                sx={{
                                    bgcolor: 'background.level2',
                                    borderRadius: 'sm',
                                    p: 1.5,
                                    my: 1.5,
                                    display: 'flex',
                                    gap: 2,
                                    '& > div': { flex: 1 },
                                }}
                            >
                                <div>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Friends
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        {loading ? '-' : friendCount}
                                    </Typography>
                                    {authState.authenticated && authState.userName !== userName && (
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                if (friendStatus === 'pending' && !isRequester) {
                                                    handleFriendResponse('accept');
                                                } else {
                                                    handleFriendAction();
                                                }
                                            }}
                                            color={
                                                friendStatus === 'friends' ? 'success' : 'primary'
                                            }
                                            disabled={
                                                friendLoading ||
                                                (friendStatus === 'pending' && isRequester)
                                            }
                                        >
                                            {friendLoading ? (
                                                <CircularProgress size={24} />
                                            ) : friendStatus === 'friends' ? (
                                                'Remove Friend'
                                            ) : friendStatus === 'pending' ? (
                                                isRequester ? (
                                                    'Pending'
                                                ) : (
                                                    'Accept Request'
                                                )
                                            ) : (
                                                'Add Friend'
                                            )}
                                        </Button>
                                    )}
                                </div>
                                <div>
                                    <Typography level="body-xs" fontWeight="lg">
                                        Followers
                                    </Typography>
                                    <Typography fontWeight="lg">
                                        {loading ? '-' : followerCount}
                                    </Typography>
                                    {authState.authenticated && authState.userName !== userName && (
                                        <Button
                                            variant="contained"
                                            onClick={
                                                isFollowing ? handleUnfollowUser : handleFollowUser
                                            }
                                            disabled={followLoading}
                                        >
                                            {followLoading ? (
                                                <CircularProgress size={24} />
                                            ) : isFollowing ? (
                                                'Unfollow'
                                            ) : (
                                                'Follow'
                                            )}
                                        </Button>
                                    )}
                                </div>
                            </Sheet>
                            {isCurrentUser && userDetails && (
                                <Button variant="contained" onClick={handleEditProfile}>
                                    Edit Profile
                                </Button>
                            )}
                        </StyledPaper>
                    </Grid>

                    {/* Collection Stats */}
                    <Grid item xs={12} md={8}>
                        <StyledPaper>
                            <Typography level="h4" fontWeight="lg">
                                Collection Stats
                            </Typography>
                            <Typography level="h4" fontWeight="lg">
                                Statistics related to the user's collection.
                            </Typography>
                        </StyledPaper>
                    </Grid>

                    {/* Collection Summary */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper>
                            <Typography level="h4" fontWeight="lg">
                                Collection Summary
                            </Typography>
                            <Typography level="h4" fontWeight="lg">
                                Details about the user's collection.
                            </Typography>
                        </StyledPaper>
                    </Grid>

                    {/* Recent Activity */}
                    <Grid item xs={12} md={12}>
                        <StyledPaper>
                            <Typography level="h4" fontWeight="lg">
                                Recent Activity
                            </Typography>
                            <Typography level="h4" fontWeight="lg">
                                User's recent activities will be displayed here.
                            </Typography>
                        </StyledPaper>
                    </Grid>
                </Grid>
            </StyledContainer>
            {userDetails && (
                <EditProfilePopup
                    open={isEditProfileOpen}
                    onClose={handleCloseEditProfile}
                    onSave={handleSaveProfile}
                    currentFavoritePokemon={userDetails?.favoritePokemonId || null}
                    currentFavoritePokemonImage={userDetails?.favoritePokemonImage || null}
                    currentCountryCode={countryCode}
                />
            )}
        </>
    );
};

export default UserProfile;
